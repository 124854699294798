import { BrowseGallery, DeleteOutline, KeyboardArrowDown, KeyboardArrowUp, Photo } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ActionModal } from 'components/Modals';
import CustomerDetailsModal from 'components/Modals/CustomerDetailsModal';
import OTable2, { CustomColumnDef } from 'components/Tables/OTable2';
import { useWarrantyData } from 'hooks/warranty.hooks';
import { useMemo, useState } from 'react';
import { COLORS } from 'util/constants/commons';
import { WarrantyData } from 'util/types/warranty-types';
import useImageStore from 'store/images';
import SkeletonLoader from 'components/SkeletonLoader';
import { useDeleteWarrantyModal } from 'store/modals';
import DeleteWarrantyModal from 'components/Modals/DeleteWarrantyModal';
import { Row } from '@tanstack/react-table';
import WarrantyDetails from './WarrantyDetails';
import WarrantyComments from './WarrantyComments';

const WarrantyTable = () => {
    const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
        customerName: '',
        customerEmail: '',
        customerNumber: '',
        postCode: '',
    });

    const { isLoading, data, error } = useWarrantyData();
    const setImageModalOpen = useImageStore((state) => state.setModalToggle);
    const setImageModalOrderNo = useImageStore((state) => state.setSelectedOrderNo);
    const setIsWarrantyImage = useImageStore((state) => state.setIsWarrantyImage);

    const rejectClaimModalData = useDeleteWarrantyModal((state) => state.modalData);
    const setRejectClaimModalData = useDeleteWarrantyModal((state) => state.setModalData);
    const isRejectClaimModalOpen = useDeleteWarrantyModal((state) => state.isModalOpen);
    const setRejectClaimModalOpen = useDeleteWarrantyModal((state) => state.setModalOpen);

    const processedData = useMemo<WarrantyData[]>(() => {
        if (!isLoading && !error) {
            return data.warranties;
        }

        return [];
    }, [data, isLoading, error]);
    const columnDefs = useMemo<CustomColumnDef<WarrantyData>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Customer Name',
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'contactable',
                header: 'Is Contactable?',
                cell: (info) => {
                    const option = {
                        color: COLORS.success,
                        text: 'Yes',
                    };

                    if (!info.row.original.contactable) {
                        option.color = 'error';
                        option.text = 'No';
                    }

                    return (
                        <Typography variant="body2" color={option.color}>
                            {option.text}
                        </Typography>
                    );
                },
            },
            {
                id: 'customerDetails',
                header: 'Customer Details',
                enableSorting: false,
                cell: ({ row }) => {
                    const warData = row.original;

                    const handleOpenDetails = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();

                        setCustomerDetails({
                            customerName: warData.name,
                            customerEmail: warData.email,
                            customerNumber: warData.number,
                            postCode: warData.postcode,
                        });

                        setCustomerModalOpen(true);
                    };

                    return (
                        <Button variant="text" onClick={handleOpenDetails} sx={{ textTransform: 'capitalize' }}>
                            Show Details
                        </Button>
                    );
                },
            },
            {
                accessorKey: 'images',
                header: 'Images',
                enableSorting: false,
                cell: ({ row }) => {
                    const handleOpenImageModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();
                        const warrantyData = row.original;

                        const warrantyId = warrantyData.id.split("'")[1];
                        setImageModalOpen(true);
                        setIsWarrantyImage(true);
                        setImageModalOrderNo(warrantyId);
                    };

                    return (
                        <Button
                            variant="text"
                            sx={{ textTransform: 'capitalize' }}
                            onClick={handleOpenImageModal}
                            startIcon={<Photo />}
                        >
                            Show Images
                        </Button>
                    );
                },
            },
            {
                accessorKey: 'orderNo',
                header: 'Order No.',
                enableSorting: false,
                cell: ({ row }) => {
                    const warrantyData = row.original;

                    const warrantyId = warrantyData.id.split("'")[1];

                    if (warrantyData.accepted) {
                        return warrantyData.orderNo;
                    }

                    return <ActionModal.AddOrderNo rowId={warrantyId} isWarranty />;
                },
            },
            {
                accessorKey: 'action',
                header: 'Action',
                enableSorting: false,
                cell: (info) => {
                    const isAccepted = info.row.original.accepted;

                    if (isAccepted) {
                        return (
                            <Button variant="text" disabled sx={{ textTransform: 'capitalize' }}>
                                Accepted Claim
                            </Button>
                        );
                    }

                    const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();
                        const warrantyData = info.row.original;

                        const warrantyId = warrantyData.id.split("'")[1];

                        setRejectClaimModalData({ warrantyId });
                        setRejectClaimModalOpen(true);
                    };

                    return (
                        <Button
                            variant="text"
                            color="error"
                            onClick={handleDelete}
                            sx={{ textTransform: 'capitalize' }}
                            startIcon={<DeleteOutline />}
                        >
                            Reject Claim
                        </Button>
                    );
                },
            },
        ],
        [setImageModalOpen, setImageModalOrderNo, setIsWarrantyImage, setRejectClaimModalOpen, setRejectClaimModalData]
    );

    const renderSubComponent = ({ row }: { row: Row<WarrantyData> }) => {
        return (
            <Stack direction="row" gap={2} flexWrap={'wrap'}>
                <WarrantyDetails rowData={row} />
                <WarrantyComments warrantyData={row.original} />
            </Stack>
        );
    };

    if (isLoading) {
        return <SkeletonLoader />;
    }

    return (
        <Box>
            <OTable2<WarrantyData>
                data={processedData}
                columns={columnDefs}
                sortId={'name'}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            />

            <CustomerDetailsModal
                isOpen={isCustomerModalOpen}
                onClose={() => setCustomerModalOpen(false)}
                customerData={customerDetails}
            />

            <DeleteWarrantyModal
                isOpen={isRejectClaimModalOpen}
                onClose={() => setRejectClaimModalOpen(false)}
                storeData={rejectClaimModalData}
            />
        </Box>
    );
};

export default WarrantyTable;
