import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Typography, Button, TextField, Stack, Divider, Box } from '@mui/material';
import { StyledPaper } from 'components/StyledPaper';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { EnquiryData } from 'util/types/enquiry-types';
import { parseISO } from 'date-fns';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { feedbackAction } from 'util/helpers/apiHelpers';
import { queryKeys } from 'util/constants/queryKeys';
import { LoadingButton } from '@mui/lab';

interface EnquiryCommentsProps {
    enquiryData: EnquiryData;
}

const EnquiryComments = ({ enquiryData }: EnquiryCommentsProps) => {
    const [newFeedback, setNewFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const scrollDummyRef = useRef<HTMLDivElement | null>(null);

    const commentList = useMemo(() => {
        if (!enquiryData?.comments) return [];

        return enquiryData.comments;
    }, [enquiryData]);
    const handleChange = (event: any) => {
        setNewFeedback(event.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.nativeEvent.keyCode === 13) {
            addNewFeedback();
        }
    };
    const addNewFeedback = useCallback(
        async (event?: any) => {
            if (!newFeedback) return;

            const payload = {
                formData: {
                    comment: newFeedback,
                },
            };

            const enqId = enquiryData.enquiry_id.replace('enquiries:', '');

            try {
                setIsLoading(true);
                let response = await feedbackAction(payload, enqId);

                if (response.success) {
                    setNewFeedback('');
                    queryClient.refetchQueries([queryKeys.enquiryData]);
                } else {
                    enqueueSnackbar(`Failed to add feedback`, { variant: 'error' });
                }
            } catch (e) {
                console.log(e, ';; error');
                enqueueSnackbar(`Failed to add feedback`, { variant: 'error' });
            } finally {
                setIsLoading(false);
            }
        },
        [enquiryData.enquiry_id, newFeedback, enqueueSnackbar, queryClient]
    );

    const scrollToBottom = () => {
        scrollDummyRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [commentList]);

    return (
        <StyledPaper elevation={6} sx={{ flex: 1 }}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" divider={<Divider flexItem />} spacing={1}>
                <Typography align="center" variant="h6" fontWeight={'bold'}>
                    Enquiry Feedbacks
                </Typography>
                <Stack
                    direction="column"
                    spacing={0.5}
                    sx={{ paddingTop: '12px', paddingBottom: '12px', flex: '1 1 auto', overflowY: 'auto', height: '275px' }}
                >
                    {commentList.length === 0 && <Typography align="center">No Feedbacks</Typography>}

                    {commentList.map((data, index) => (
                        <StyledPaper
                            key={index}
                            elevation={1}
                            sx={{ flex: 1, alignSelf: 'end', borderRadius: '8px', minWidth: '12rem' }}
                        >
                            <Typography variant="body2" sx={{ opacity: 0.85 }}>
                                {parseISO(data.time).toLocaleDateString('en-AU', {
                                    year: '2-digit',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography align="right" key={index} sx={{ whiteSpace: 'pre-wrap' }}>
                                {data.comment}
                            </Typography>
                        </StyledPaper>
                    ))}

                    <Box ref={scrollDummyRef}></Box>
                </Stack>

                <Stack direction="row" spacing={4} alignItems="center">
                    <TextField onChange={handleChange} onKeyDown={handleKeyPress} value={newFeedback} fullWidth>
                        Add Comment
                    </TextField>
                    <LoadingButton loading={isLoading} variant="contained" size="medium" onClick={addNewFeedback}>
                        Submit
                    </LoadingButton>
                </Stack>
            </Stack>
        </StyledPaper>
    );
};

export default EnquiryComments;
