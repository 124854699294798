import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';

export const setFinishedOrders = async (finished, query) => {
    return await API.post(`${API_ENDPOINTS.SET_FINISHED}?finished=${finished}&product=${query}`)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const setQuota = async (quota, query) => {
    return await API.post(`${API_ENDPOINTS.SET_QUOTA}?quota=${quota}&product=${query}`)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const acceptPrivacyNotice = async (isAccept) => {
    return await API.post(API_ENDPOINTS.PRIVACY_NOTICE)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const createEnquiry = async (enquiryData) => {
    return await API.post(API_ENDPOINTS.ENQUIRIES.POST, enquiryData)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const updateEnquiry = async (enqID, enquiryData) => {
    return await API.put(`${API_ENDPOINTS.ENQUIRIES.PUT}/${enqID}`, enquiryData)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const listEnquiryProducts = async (enquiryProductData) => {
    return await API.post(`${API_ENDPOINTS.ENQUIRIES.POST}/products`, enquiryProductData)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const unlinkEnquiry = async (enquiryId) => {
    return await API.post(`${API_ENDPOINTS.ENQUIRIES.POST}/unlink/${enquiryId}`)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch((error) => {
            return {
                success: false,
                error,
            };
        });
};

export const enquiryAction = async (actionData, enquiryId) => {
    return await API.post(`${API_ENDPOINTS.ENQUIRIES.POST}/actions/${enquiryId}`, actionData)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch(({ error }) => {
            return {
                success: false,
                error,
            };
        });
};

export const feedbackAction = async (feedback, id, isWarranty = false) => {
    return await API.post(`/feedback/${id}?warranty=${!!isWarranty}`, feedback)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch(({ error }) => {
            return {
                success: false,
                error,
            };
        });
};

export const warrantyAction = async ({ isAccepted, orderNo, id }) => {
    return await API.patch(`${API_ENDPOINTS.WARRANTY.POST}/${id}?orderNo=${orderNo}&accepted=${isAccepted}`)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch(({ error }) => {
            return {
                success: false,
                error,
            };
        });
};

export const deleteWarranty = async (warrantyId) => {
    return await API.delete(`${API_ENDPOINTS.WARRANTY.DELETE}/${warrantyId}`)
        .then(({ data }) => {
            return {
                success: true,
                data,
            };
        })
        .catch(({ error }) => {
            return {
                success: false,
                error,
            };
        });
};
