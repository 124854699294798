import { TabPanel } from "components/TabPanel"
import { Quotas } from "./Quotas"
import { useState } from "react"
import { Box, Tab, Tabs } from "@mui/material"


const DashboardSettings = () => {
    const [tabValue, setTabValue] = useState(0)

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="dashboard quotas">
                    <Tab label="Quotas" id="dashboard-quotas" aria-controls="dashboard-quotas-tab" />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                <Quotas query={'umbrella'} title={'Umbrellas Quotas'}/>
                <Quotas query={'awnings'} title={'Awnings Quotas'}/>
            </TabPanel>
        </>
    )
}

export default DashboardSettings