import { Box, CircularProgress, circularProgressClasses, Grid, LinearProgress, Skeleton, Stack, Typography } from "@mui/material"
import { animated, useSpring } from "@react-spring/web"
import { Animated } from "components/Animated"
import { StyledPaper } from "components/StyledPaper"
import { useDashboardQuotas } from "hooks/dashboard.hooks"
import { useMemo, useState } from "react"
import { COLORS } from "util/constants/commons"


const QuotaProgress = ({ query, title }) => {
    const { isLoading, data, isError } = useDashboardQuotas(query)

    const props = useSpring({
        from: {value: 0},
        delay: 600
    })

    const quotaData = useMemo(() => {
        if (isError || isLoading) {
            return {
                quota: 10,
                required: 10,
                current: 0
            }
        }

        if (query === 'umbrella') {
            return {
                quota: data.quotas[0]?.quota || 10,
                required: data.quotas[0]?.required || 10,
                current: data.quotas[0]?.current || 0
            }
        }

        return {
            quota: data.quotas[1]?.quota || 10,
            required: data.quotas[1]?.required || 10,
            current: data.quotas[1]?.current || 0
        }
    }, [data, isError, isLoading, query])

    const progressPercent = () => {
        if (!isLoading) {
            const isQuotaEqualLess = quotaData.quota >= quotaData.required

            const percentage = isQuotaEqualLess ? Math.round((quotaData.current/quotaData.quota) * 100) : Math.round((quotaData.current/quotaData.required) * 100)
            
            if (percentage > 100) {
                return 100
            }

            return percentage
        }

        return 0
    }

    const progressColor = useMemo(() => {
        let progressBarColor = COLORS.primary

        const isRequiredMet = quotaData.current >= quotaData.required
        const isQuotaGreaterRequired = quotaData.quota > quotaData.required

        const isGoalMet = quotaData.current >= quotaData.required && quotaData.current >= quotaData.quota

        if (!isRequiredMet) {
            progressBarColor = COLORS.warning_primary 
        }
        
        if (isRequiredMet && isQuotaGreaterRequired) {
            progressBarColor = COLORS.primary_darker 
        }

        if (isGoalMet) {
            progressBarColor = COLORS.success
        }

        return {progressBarColor}
    }, [quotaData])

    const AnimatedCircularProgress = animated(CircularProgress)

    return (
        <StyledPaper sx={{flex: 1, mt: 4, maxWidth: 750, minWidth: 600}}>
            <Stack direction="column" spacing={0} sx={{mb: 4}}>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>
                    {title}
                </Typography>
            </Stack>

            <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Grid container item xs={6}>
                    <Grid item xs={6}>
                        <Stack direction="column" flex={1}>
                            <Typography spacing={2} variant="h6">
                                Target Quota
                            </Typography>
                            <Animated.Number variant="h4" number={quotaData.quota} />
                        </Stack>
                    </Grid>

                    <Grid item xs={6}>
                        <Stack direction="column" flex={1}>
                            <Typography spacing={2} variant="h6">
                                Items Finished
                            </Typography>
                            <Animated.Number sx={{color: progressColor.progressBarColor}} variant="h4" number={quotaData.current} />
                        </Stack>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: 'inline-flex', position: 'relative', justifyContent: 'center'}}>
                        <CircularProgress 
                            variant="determinate" 
                            size={200} 
                            sx={{
                                color: COLORS.neutral_gray_light
                            }}
                            thickness={4}
                            value={100}
                        />
                        <AnimatedCircularProgress 
                            variant="determinate" 
                            size={200} 
                            thickness={4}
                            sx={{
                                color: progressColor.progressBarColor,
                                position: 'absolute',
                                [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                }
                            }}
                            value={props.value.to(() => progressPercent())}
                        />

                        <Box sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="h2" color={progressColor.progressBarColor}>
                                {progressPercent()}%
                            </Typography> 
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            
        </StyledPaper>
    )

}

export default QuotaProgress